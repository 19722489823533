import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { getImage, StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundImage: `url(${IntroBackground})`,
    minHeight: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
    backgroundSize: "cover",
    transitionDuration: "1000ms",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        margin: "-1rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "3em",
      },
    },
    "&::before": {
      opacity: "1 !important",
      position: "absolute",
      zIndex: "0 !important",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "6em",
    position: "relative",
    zIndex: "1 !important",

    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
  },
}));

const Main = ({ title, background }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const image = getImage(background);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      className={classes.root}
      preserveStackingContext
    >
      <div>
        <Container maxWidth="lg">
          <Typography component="h1" variant="h5" className={classes.title}>
            {t(title)}
          </Typography>
          <Grid
            container
            spacing={1}
            align="center"
            className={classes.brushBackground}
          ></Grid>
        </Container>
      </div>
    </BackgroundImage>
  );
};

export default Main;
