import React, { useRef, useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import useOnScreen from "../components/hooks/useOnScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  wrapper: {
    width: "100%",
    height: "100%",
  },
  paper: {
    height: "100%",
    width: "100%",
  },
}));

const SlideAnimation = ({ children, direction, appearance }) => {
  const classes = useStyles({});
  const ref = useRef();
  const [checked, setChecked] = useState(false);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      setChecked(true);
    }
  }, [isVisible]);

  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.wrapper}>
        <Slide
          timeout={{
            appear: 1000,
            enter: appearance || 1000,
            exit: 1000,
          }}
          direction={direction || "left"}
          in={checked}
          mountOnEnter
          unmountOnExit>
          <div className={classes.paper}>{children}</div>
        </Slide>
      </div>
    </div>
  );
};

export default SlideAnimation;
