import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "2rem",
  },
  h2: {
    fontSize: "3em",
  },
  button: {
    position: "relative",
    padding: "15px 25px",
    borderRadius: "30px",
    background: "transparent",
    border: "2px solid #fff",
    color: "#fff",
    display: "inline-block",
    textDecoration: "none",
    fontWeight: "700",
    "&:hover": {
      background: "#fff",
      boxShadow: "0px 0px 20px 2px #fff",
      color: "#09333F",
      border: "none",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ContanctCA = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={8}>
        <h2 className={classes.h2}>Let’s make great things together</h2>
      </Grid>
      <Grid item xs={12} md={4} className={classes.buttonContainer}>
        <a href="/contact" className={classes.button}>
          Start Project
        </a>
      </Grid>
    </Grid>
  );
};

export default ContanctCA;
