import React from "react";
import { Accordion, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles(() => ({
  buttons: {
    background: "#3A5C65",
    textAlign: "start",
    justifyContent: "space-between",
    color: "white",
  },
  accordionBox: {
    marginTop: "1rem",
    marginBottom: "1rem",
    color: "#fff",
    borderRadius: "10px",
    background: "#3A5C65",
  },
  details: {
    marginTop: "1rem",
    marginBottom: "1rem",
    color: "#fff",
    maxWidth: "100%",
  },
  roots: {
    borderRadius: "10px",
  },
}));
const StrategyAccordion = ({ label, text }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container className={classes.roots}>
      <Accordion className={classes.accordionBox}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.buttons}
        >
          <Typography>{t(label)}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Typography>{t(text)}</Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default StrategyAccordion;
