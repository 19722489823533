import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SlideAnimation from "../SlideAnimation";
import StrategyAccordion from "../StrategyAccordion";

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderRadius: "10px",
    marginTop: "2rem",
  },
}));

const Dropdowns = ({ items }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.accordion}>
      {items.map(({ label, text }, idx) => (
        <SlideAnimation
          key={idx}
          direction="right"
          appearance={(idx + 1) * 500}
        >
          <StrategyAccordion
            label={label}
            text={text}
            className={classes.accordion}
          />
        </SlideAnimation>
      ))}
    </Grid>
  );
};

export default Dropdowns;
