import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Intro from "../components/services/Main";
import Strategy from "../components/services/Strategy";
import { makeStyles } from "@material-ui/core";
import { graphql } from "gatsby";
import { Container } from "@material-ui/core";
import Dropdowns from "../components/services/Dropdowns";
import ContanctCA from "../components/ContanctCA";
import Seo from "../components/SEO/Seo";
import { getImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "start",
    color: "#fff",
    background: "#09333F",
    backgroundSize: "100% 100%",
  },
  text: {
    paddingTop: "2rem",
    background: "transparent",
  },
  dropdown: {
    borderTop: "2px solid lightgray",
    marginTop: "2rem",
    paddingTop: "2rem",
  },
  containerBottom: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const getSeoImage = (imageSharp) => getImage(imageSharp)?.images?.fallback?.src;

const Service = ({ pageContext, data }) => {
  const { site } = data;
  const classes = useStyles();
  const { item, slug, image, seo } = pageContext;
  const { t } = useTranslation();

  return (
    <>
      <Seo
        site={site}
        title={t(seo.siteTitle)}
        description={t(seo.metaDescription)}
        image={getSeoImage(image)}
      />
      <div id={slug} className={classes.root}>
        <Navbar />
        <Intro title={item.title} background={image} />
        <Container maxWidth="lg">
          <Strategy title={item.titleTextSmall_1} text={item.text1} />
          <Dropdowns items={item.dropdowns} />
          <div id="dropdown">
            <ContanctCA />
          </div>
        </Container>

        <Footer />
      </div>
    </>
  );
};

export default Service;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        organization {
          name
          url
          logo
        }
      }
    }
  }
`;
