import React from "react";
import { Typography, Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Strategy = ({ title, text, dropdowns }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography id="text" component="div"></Typography>
      <Box my={4}>
        <Typography component="h2" variant="h5">
          {t(title)}
        </Typography>
      </Box>
      <Typography component="p" variant="h6">
        {t(text)}
      </Typography>
    </div>
  );
};

export default Strategy;
